import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useLanguageStore, useNotificationStore, useScriptStore, useValuesStore} from "@/shared/stores";
import {userStoreActions, userStoreGetters} from '@/shared/helpers/userStoreHelper.js';
import {usePrintersStore, useCouponsStore, useCounterStore, useWarehouseStore, useAdministrationStore, useMenuStore, useRoomsStore, useRoomsOrdersStore, useOrdersStore, useOrdersActionsStore, usePluginsStore} from "@/console-new/stores";
import {playLockSound} from "@/console-new/helpers/sound";
import {getNative, isAndroid, isNative} from "@/shared/helpers/webapp";

export const useUserStore = defineStore('user', {
	state: () => ({
		user: null,
		businesses: null,
		currBusinessId: null,
		businessTerminals: null,
		businessHiddenTerminalsUsers: null,
		changedHiddenTerminalsUsers: {},
		businessUsers: null,
		deliveryCouriers: null,
		dismissedFiscalPopup: false,
		shouldOpenFiscal: false,
		noBusinesses: false,
		refreshKeyBanner: 0,
		allowLocalTerminals: false,
		manualCode: null,
		unlockUserId: null,
		timeoutLock: null,
		lockListener: null,
		darkMode: false,
		shouldOpenBusinessInfo: false,
		modBusinessInfos: {}
	}),
	getters: {
		currentTypeBanner: state => {
			state.refreshKeyBanner;
			if(!state || !state.currPermissions || !state.currPermissions.length || isNative()) {
				return null;
			}
			let permissions = state.currPermissions;
			if(!state.currBusiness.plan_id && !checkSessionType('subscription', state.currBusinessId) && permissions.includes('admin')) {
				return 'subscription';
			} else if(!state.currBusiness.config_settings && permissions.includes('admin')) {
				return 'link';
			} else if(!state.currBusiness.config_tables && !checkSessionType('qrs', state.currBusinessId) && permissions.includes('menu')) {
				return 'qrs';
			} else if(!state.currBusiness.config_payments && state.currPlans['order_pay'] && permissions.includes('admin')) {
				return 'payments';
			} else if(state.currBusiness.plan_id && (!state.currBusiness.legal_address_id || !state.currBusiness.sdi || !state.currBusiness.vat || !state.currBusiness.fiscal_code || !state.currBusiness.fiscal_name) && !checkSessionType('fiscal', state.currBusinessId) && permissions.includes('admin')) {
				return 'fiscal';
			} else if(state.currBusiness.plan_id && !state.currPlans['order_pay'] && !state.currPlans['pay_bill'] && !checkSessionType('order_pay', state.currBusinessId) && permissions.includes('admin')) {
				return 'order_pay';
			}
			return null;
		},
		currBusiness: state => {
			return userStoreGetters.currBusiness(state);
		},
		currBusinessColor: state => {
			if(!state.currBusinessId || !state.businesses) {
				return "#2352A2";
			}
			return state.currBusiness && state.currBusiness.color ? '#'+state.currBusiness.color : "#2352A2";
		},
		currPermissions: state => {
			return userStoreGetters.currPermissions(state);
		},
		requirePin: state => {
			return state.currBusiness && state.currBusiness.permissions && state.currBusiness.permissions.length && state.currBusiness.permissions[0].require_code === "1";
		},
		currSubscription: state => {
			if(!state.currBusiness || !state.currBusiness.plan_id || !state.currBusiness.active_plan) {
				return null;
			}
			if(state.currBusiness.active_plan === 'old') {
				return 'base';
			}
			return state.currBusiness.active_plan;
		},
		currPlans: state => {
			let currPlans = {
				order_pay: true,
				delivery: true,
				takeaway: true,
				pos: true,
				counter: true,
				pay_bill: true,
			};
			if(state && state.currBusiness) {
				currPlans.order_pay = !!state.currBusiness.plan_plugin_order_pay;
				currPlans.delivery = !!state.currBusiness.plan_plugin_delivery;
				currPlans.takeaway = !!state.currBusiness.plan_plugin_takeaway;
				currPlans.pos = !!state.currBusiness.plan_plugin_pos;
				currPlans.counter = !!state.currBusiness.plan_plugin_counter;
				currPlans.pay_bill = !!state.currBusiness.plan_plugin_pay_bill;
			}
			return currPlans;
		},
		menuWord: state => {
			let languageStore = useLanguageStore();

			if(state.currBusiness && state.currBusiness.type === "other") {
				return languageStore.getString("catalogue");
			} else {
				return languageStore.getString("menu");
			}

		},
		deliveryWord: state => {
			let languageStore = useLanguageStore();

			if(state.currBusiness && state.currBusiness.type === "other") {
				return languageStore.getString("delivery_other");
			} else {
				return languageStore.getString("delivery");
			}

		},
		takeawayWord: state => {
			let languageStore = useLanguageStore();

			if(state.currBusiness && state.currBusiness.type === "other") {
				return languageStore.getString("takeaway_other");
			} else {
				return languageStore.getString("takeaway");
			}

		},
		ingredientsWord: state => {
			let languageStore = useLanguageStore();

			if(state.currBusiness && state.currBusiness.type === "other") {
				return languageStore.getString("materials");
			} else {
				return languageStore.getString("ingredients");
			}
		},
		isLightspeedActive: state => {
			return state.user && (state.user.hasOwnProperty("lightspeed_refresh_token") && state.user.lightspeed_refresh_token !== null) && state.currBusiness && state.currBusiness.integration_id === 3;
		},
		isWeconstudioActive: state => {
			return state.user && state.currBusiness && state.currBusiness.integration_id === 4;
		},
		completedFiscal: state => {
			return !!state.currBusiness && !!state.currBusiness.legal_address_id && !!state.currBusiness.vat && !!state.currBusiness.fiscal_code && !!state.currBusiness.fiscal_name;
		},
		shouldFillFiscal: state => {
			return !!state.currBusiness && !!state.currBusiness.plan_id && !state.completedFiscal;
		},
		userHiddenTerminalsIds: state => {
			let currUserId = state?.user?.user_id && state.user.user_id ? state.user.user_id : null;
			if(!currUserId || !state.businessHiddenTerminalsUsers) {
				return [];
			}
			let returningTerminalsIds = [];
			Object.keys(state.businessHiddenTerminalsUsers).forEach(terminalId => {
				if(state.businessHiddenTerminalsUsers[terminalId].includes(Number(currUserId))) {
					returningTerminalsIds.push(terminalId);
				}
			});
			return returningTerminalsIds;
		},
	},
	actions: {
		addLockChecker() {
			document.removeEventListener("click", this.lockListener);
			document.removeEventListener("scroll", this.lockListener);
			document.addEventListener("click", this.lockListener);
			document.addEventListener("scroll", this.lockListener, true);
			this.lockListener();
		},
		lockListener() {
			if(!this.unlockUserId) {
				return;
			}
			if(this.timeoutLock) {
				clearTimeout(this.timeoutLock);
			}
			this.timeoutLock = setTimeout(this.pinLock, 12500);
		},
		pinLock(sound = true) {
			let ordersActionsStore = useOrdersActionsStore();
			if(ordersActionsStore.ordersPayment) {
				this.lockListener();
				return;
			}
			let counterStore = useCounterStore();
			if(counterStore.paymentActive) {
				this.lockListener();
				return;
			}
			this.unlockUserId = null;
			clearTimeout(this.timeoutLock);
			this.timeoutLock = null;
			document.removeEventListener("click", this.lockListener);
			document.removeEventListener("scroll", this.lockListener);
			if(sound) {
				playLockSound();
			}
			const valuesStore = useValuesStore();
			if(this.router && this.router.currentRoute && this.router.currentRoute.value && this.router.currentRoute.value.fullPath.startsWith("/ops")) {
				valuesStore.forceCloseAllBottomSheets = true;
				setTimeout(() => {
					valuesStore.forceCloseAllBottomSheets = false;
				}, 1000);
			}
		},
		init(redirectUrlAuth = null) {
			userStoreActions.init(this, redirectUrlAuth);
		},
		initDarkMode() {
			userStoreActions.initDarkMode(this);
		},
		getBusinessLocation(callback = null) {
			userStoreActions.getBusinessLocation(this, callback);
		},
		normalizeBusiness(business) {
			return userStoreActions.normalizeBusiness(business);
		},
		normalizeAddress(address) {
			return userStoreActions.normalizeAddress(address);
		},
		refreshUserAccount(callback = null) {
			userStoreActions.refreshUserAccount(this, callback);
		},
		refreshBusiness(businessId, callback = null) {
			userStoreActions.refreshBusiness(this, businessId, callback);
		},
		getBusinessUsers(callback = null) {
			userStoreActions.getBusinessUsers(this, callback);
		},
		linkSatispay(code, callback = null) {

			let self = this;
			API.init().satispayCreate(this.currBusinessId, 1, code, r => {
				if (r && r.result === 'OK') {
					self.refreshBusiness(self.currBusinessId, function () {
						callback && callback(true);
					});
				} else {
					callback && callback(false, r.details ? r.details : null);
				}
			});
		},
		linkVoucherly(code, callback = null) {

			let self = this;
			API.init().voucherlyCreate(this.currBusinessId, 1, code, r => {
				if (r && r.result === 'OK') {
					self.refreshBusiness(self.currBusinessId, function () {
						callback && callback(true);
					});
				} else if(r?.details === "no_gateways") {
					self.refreshBusiness(self.currBusinessId, function () {
						callback && callback(false, r?.details);
					});
				} else {
					callback && callback(false, r?.details);
				}
			});
		},
		getBusinessTerminals(allowLocal, callback = null) {
			if(this.allowLocalTerminals !== allowLocal) {
				this.allowLocalTerminals = allowLocal;
				this.businessTerminals = null;
				this.businessHiddenTerminalsUsers = null;
				this.changedHiddenTerminalsUsers = {};
			}
			if(this.businessTerminals != null) { callback && callback(this.businessTerminals); return; }
			if(!this.currBusinessId) { callback && callback([]); return; }

			let self = this;
			API.init().getTerminals(this.currBusinessId, function (response) {
				if (response && response.result === "OK") {
					let finalTerminals = [];
					let finalHiddenTerminalsUsers = {};
					if(response.terminals && response.terminals.length > 0) {
						finalTerminals = response.terminals;
					}
					if(response.terminals_hide_users && response.terminals_hide_users.length > 0) {
						response.terminals_hide_users.forEach(terminalHideUser => {
							if(!finalHiddenTerminalsUsers[terminalHideUser.terminal_id]) {
								finalHiddenTerminalsUsers[terminalHideUser.terminal_id] = [];
							}
							finalHiddenTerminalsUsers[terminalHideUser.terminal_id].push(Number(terminalHideUser.user_id));
						});
					}
					if(allowLocal) {
						if(localStorage.getItem("tapPhoneActive") === "1") {
							finalTerminals.push({
								terminal_id: "tap_phone",
								name: "Tap Phone",
							})
						}
						if(localStorage.getItem("bluetoothActive") === "1") {
							finalTerminals.push({
								terminal_id: "bluetooth",
								name: "Bluetooth",
							})
						}
						if(localStorage.getItem("handoffActive") === "1" || (isAndroid() && Android.getManufacturer() === "BBPOS")) {
							finalTerminals.push({
								terminal_id: "handoff",
								name: "Local",
							})
						}
					}
					self.businessTerminals = finalTerminals;
					self.businessHiddenTerminalsUsers = finalHiddenTerminalsUsers;
					self.changedHiddenTerminalsUsers = {};
					callback && callback(self.businessTerminals);
				}
			});
		},
		updateBusinessTerminals(newTerminal, callback = null) {
			if(!this.currBusinessId) { callback && callback([]); return; }

			let finalTerminals = this.businessTerminals ? Object.assign([], this.businessTerminals) : [];
			if(this.businessHiddenTerminalsUsers && Object.keys(this.businessHiddenTerminalsUsers).length > 0) {
				finalTerminals.forEach(terminal => {
					if(this.changedHiddenTerminalsUsers?.[terminal.terminal_id]) {
						let foundTerminalHiddenUsers = this.businessHiddenTerminalsUsers[terminal.terminal_id];
						if(foundTerminalHiddenUsers) {
							terminal.hide_users = foundTerminalHiddenUsers;
						}
					}
				});

			}

			if(newTerminal) { finalTerminals.push(newTerminal); }
			API.init().updateTerminals(this.currBusinessId, JSON.stringify(finalTerminals), response => {
				if (response && response.result === "OK") {
					if(response.new_terminal) {
						this.businessTerminals.push(response.new_terminal);
					}
					callback && callback(true);
				} else {
					callback && callback(false, response && response.details ? response.details : null);
				}
			});
		},
		updateBusinessTerminalConfiguration(terminalId, configuration, callback = null) {

			if(!this.currBusinessId) { callback && callback(true); return; }
			API.init().updateTerminalConfiguration(this.currBusinessId, terminalId, configuration, response => {
				if (response && response.result === "OK") {
					callback && callback(true);
				} else if(response && response.result === "KO") {
					callback && callback(false);
				}
			});

		},
		deleteTerminal(terminalId, callback = null) {
			if(!this.currBusinessId) { callback && callback(true); return; }

			API.init().deleteTerminal(this.currBusinessId, terminalId, response => {
				if (response && response.result === "OK") {
					this.businessTerminals = this.businessTerminals.filter(terminal => terminal.terminal_id !== terminalId);
					if(this.businessHiddenTerminalsUsers && this.businessHiddenTerminalsUsers[terminalId]) {
						delete this.businessHiddenTerminalsUsers[terminalId];
					}
					callback && callback(true);
				} else {
					callback && callback(false, response && response.details ? response.details : null);
				}
			});
		},
		addQromopayCredentials(name, url, callback = null) {
			if(!this.currBusinessId) { callback && callback(true); return; }

			API.init().addQromopayCredentials(this.currBusinessId, name, url, response => {
				if (response && response.result === "OK") {
					callback && callback(true, response);
				} else {
					callback && callback(false, response && response.details ? response.details : null);
				}
			});
		},
		deleteQromopayCredential(webhookId, callback = null) {
			if(!this.currBusinessId) { callback && callback(true); return; }

			API.init().deleteQromopayCredential(this.currBusinessId, webhookId, response => {
				if (response && response.result === "OK") {
					callback && callback(true);
				} else {
					callback && callback(false, response && response.details ? response.details : null);
				}
			});
		},
		addNewWebhook(name, url, callback = null) {
			if(!this.currBusinessId) { callback && callback(true); return; }

			API.init().addNewWebhook(this.currBusinessId, name, url, response => {
				if (response && response.result === "OK" && response.auth && response.webhook_id) {
					callback && callback(true, response);
				} else {
					callback && callback(false, response && response.details ? response.details : null);
				}
			});
		},
		deleteWebhook(webhookId, callback = null) {
			if(!this.currBusinessId) { callback && callback(true); return; }

			API.init().deleteWebhook(this.currBusinessId, webhookId, response => {
				if (response && response.result === "OK") {
					callback && callback(true);
				} else {
					callback && callback(false, response && response.details ? response.details : null);
				}
			});
		},
		changeBusiness(newBusinessId) {
			if(!this.businesses[newBusinessId]) { return }
			this.businessTerminals = null;
			this.businessHiddenTerminalsUsers = null;
			this.changedHiddenTerminalsUsers = {};
			this.deliveryCouriers = null;
			this.pinLock(false);
			useWarehouseStore().resetData();
			useUserStore().businessUsers = {};
			useMenuStore().resetData();
			usePrintersStore().businessPrinters = null;
			useCouponsStore().businessCoupons = null;
			useRoomsStore().rooms = null;
			useRoomsStore().tables = null;
			useCounterStore().finishEditOrder();
			useCounterStore().shortcuts = null;
			useOrdersStore().resetData();
			usePluginsStore().resetData();
			useAdministrationStore().resetData();
			useRoomsOrdersStore().resetRoomsTablesCalls();
			this.currBusinessId = newBusinessId;
			localStorage.setItem("current-business-id",this.currBusinessId);
		},
		updateBusinessFunnelPreferences(dismissedFunnel, callback = null) {

			API.init().updateBusinessFunnelPreferences(this.currBusinessId, dismissedFunnel, response => {
				if (response && response.result === "OK") {
					this.refreshBusiness(this.currBusinessId,function () {
						callback && callback(true);
					});
				} else {
					callback && callback(false);
				}
			});

		},
		updateBusiness(updateData, callback = null) {

			let self = this;
			API.init().updateBusiness(this.currBusinessId, updateData, function (response) {
				if (response && response.result === "OK") {
					self.refreshBusiness(self.currBusinessId,function () {
						callback && callback(true);
					});
				} else if(response && response.result === "KO" && response.details && response.details === "auth_denied") {
					let notificationStore = useNotificationStore();
					notificationStore.showNotification("permission-denied");
				} else {
					callback && callback(false);
				}
			});

		},
		createLinkBusiness(link, callback = null) {

			let self = this;
			API.init().createLink(this.currBusinessId, link, function (response) {
				if(response && response.result === "OK") {
					self.refreshBusiness(self.currBusinessId, function () {
						callback && callback(true, null);
					});
				} else {
					callback && callback(false, response.details ? response.details : null);
				}
			});
		},
		updatePaymentMethods(callback = null) {

			API.init().updatePaymentMethods(this.currBusinessId, this.currBusiness.accept_cash, this.currBusiness.accept_cards, this.currBusiness.accept_bitcoin, this.currBusiness.accept_satispay, this.currBusiness.accept_klarna, this.currBusiness.accept_voucherly, function (response) {
				if (response && response.result === 'OK') {
					callback && callback(true, null);
				} else {
					callback && callback(false, response.details);
				}
			});

		},
		updateHidePrices(callback = null) {
			API.init().updateHidePrices(this.currBusinessId, this.currBusiness.hide_prices, function (response) {
				if (response && response.result === 'OK') {
					callback && callback(true, null);
				} else {
					callback && callback(false, response.details);
				}
			});
		},
		updateDeliveryStatus(callback = null) {
			API.init().updateBusinessDelivery(this.currBusinessId, this.currBusiness.delivery ? 1 : 0, function (r) {
				if(r && r.result === "OK") {
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		updateDeliveryCash(callback = null) {

			API.init().updateDeliveryCash(this.currBusinessId, this.currBusiness.delivery_cash, function (response) {
				if (response && response.result === 'OK') {
					callback && callback(true);
				} else {
					callback && callback(false, response ? response.details : null);
				}
			});
		},
		getDeliveryCouriers(callback = null) {
			if(this.deliveryCouriers != null) { callback && callback(this.deliveryCouriers); return; }
			if(!this.currBusinessId) { callback && callback([]); return; }

			let self = this;
			API.init().getDeliveryCouriers(this.currBusinessId, function (response) {
				if (response && response.couriers) {
					self.deliveryCouriers = response.couriers;
					callback && callback(response.couriers);
				} else {
					callback && callback(null);
				}
			});

		},
		updateDeliveryCouriers(callback = null) {

			let couriers = this.deliveryCouriers ? this.deliveryCouriers.map(c => Number(c.courier_id)) : [];
			API.init().updateDeliveryCouriers(this.currBusinessId, couriers, function (response) {
				if (response && response.result === 'OK') {
					callback && callback(true);
				} else {
					callback && callback(false, response ? response.details : null);
				}
			});

		},
		updateTakeawayCash(callback = null) {
			API.init().updateTakeawayCash(this.currBusinessId, this.currBusiness.takeaway_cash, function (response) {
				if (response && response.result === 'OK') {
					callback(true);
				} else {
					callback(false, response ? response.details : null);
				}
			});
		},
		getTakeawayTimetables(callback) {
			API.init().getTakeawayTimes(this.currBusinessId, function (response) {
				if (response && response.result === "OK" && response.takeaway_times) {
					callback(response.takeaway_times.map(t => {
						return {
							hour_start: Number(t.hour_start),
							hour_end: Number(t.hour_end),
							minute_start: Number(t.minute_start),
							minute_end: Number(t.minute_end),
							week_day: Number(t.date_week),
							quantity: t.num_orders ? Number(t.num_orders) : null,
						};
					}));
				}
			});
		},
		getDeliveryTimetables(callback) {
			API.init().getDeliveryTimes(this.currBusinessId, function (response) {
				if (response && response.result === "OK" && response.delivery_times) {
					callback(response.delivery_times.map(t => {
						return {
							hour_start: Number(t.hour_start),
							hour_end: Number(t.hour_end),
							minute_start: Number(t.minute_start),
							minute_end: Number(t.minute_end),
							week_day: Number(t.date_week),
							quantity: t.num_orders ? Number(t.num_orders) : null,
						};
					}));
				}
			});
		},
		updateTakeawayTimetables(timetables, callback) {
			let times = timetables.map(t => {
				return {
					hour_start: t.hour_start,
					hour_end: t.hour_end,
					minute_start: t.minute_start,
					minute_end: t.minute_end,
					date_week: t.week_day,
					num_orders: t.quantity,
				};
			});
			API.init().updateTakeawayTimes(this.currBusinessId, JSON.stringify(times), function (result) {
				if(result && result.result === "OK") {
					callback();
				}
			});
		},
		updateDeliveryTimetables(timetables, callback) {
			let times = timetables.map(t => {
				return {
					hour_start: t.hour_start,
					hour_end: t.hour_end,
					minute_start: t.minute_start,
					minute_end: t.minute_end,
					date_week: t.week_day,
					num_orders: t.quantity,
				};
			});
			API.init().updateDeliveryTimes(this.currBusinessId, JSON.stringify(times), function (result) {
				if(result && result.result === "OK") {
					callback();
				}
			});
		},
		subOtherPaymentsMethods(dataAction, callback = null) {

			API.init().stripeSubscriptionMetered(this.currBusinessId, dataAction, (response) => {

				if (response.result === "OK" && response.client_secret) {
					callback(response.client_secret);
				} else {
					callback(false);
				}
			})
		},
		stripeUploadMenuService(callback) {

			API.init().stripeUploadMenuService(this.currBusinessId, (response) => {
				if (response && response.result === "OK" && response.client_secret) {
					callback(response.client_secret);
				} else {
					callback(false);
				}
			});

		},
		subToPlan(planName, billingCycle, callback = null) {

			let bCycle = billingCycle === 0 ? "year" : "month";
			API.init().stripeSubscribeToPlan(this.currBusinessId, planName, bCycle, function (r) {
				if(r && r.result === "OK") {
					if(r.client_secret) {
						callback && callback(r.client_secret);
					} else if(r.hasOwnProperty("partner_activation") && r.partner_activation) {
						callback && callback("partner");
					} else {
						callback && callback(false);
					}
				} else {
					callback && callback(false, r?.details || null);
				}
			});

		},
		subBase(billingCycle, otherPlugin, callback = null) {

			let bCycle = billingCycle === 0 ? "year" : "month";
			let withPlugins = otherPlugin ? [otherPlugin] : null;
			API.init().stripeSubscriptionBase(this.currBusinessId, bCycle, withPlugins, function (r) {
				if(r && r.result === "OK") {
					if(r.client_secret) {
						callback && callback(r.client_secret);
					} else if(r.hasOwnProperty("partner_activation") && r.partner_activation) {
						callback && callback("partner");
					} else {
						callback && callback(false);
					}
				} else {
					callback && callback(false);
				}
			});

		},
		createBtcStore(passwordQromo, callback = null) {

			let self = this;
			API.init().btcpayCreate(this.currBusinessId, passwordQromo, function (response) {
				if (response && response.result === "OK") {
					if(response.store_id) {
						self.currBusiness.btcpay_store_id = response.store_id;
					}
					callback && callback(true, null);
				} else {
					callback && callback(false, response.details ? response.details : null);
				}
			});

		},
		testBtcStore(callback = null) {

			API.init().btcpayInvoiceTest(this.currBusinessId, function (response) {
				if (response && response.result === "OK") {
					callback && callback(true, response);
				} else {
					callback && callback(false, response.details ? response.details : null);
				}
			});
		},
		resetModBusinessInfos() {
			this.modBusinessInfos = {};
			if(this.currBusiness) {
				this.modBusinessInfos = {
					vat: this.currBusiness.vat,
					fiscal_name: this.currBusiness.fiscal_name,
					legal_address: this.currBusiness.legal_address,
					address: this.currBusiness.address,
					fiscal_code: this.currBusiness.fiscal_code,
					sdi: this.currBusiness.sdi,
					company_email: this.currBusiness.company_email,
					email_pec: this.currBusiness.email_pec,
					company_phone: this.currBusiness.company_phone,
				};
			}
		},
		showStripeButton() {
			return this.currBusiness && this.currBusiness.stripe_account && this.currBusiness.config_payments;
		},
		mandatoryUserVerificationForBusiness() {
			let currBus = this.currBusiness;
			return currBus && currBus.stripe_account && currBus.config_payments && ['small', 'custom', 'big'].includes(currBus.tier) && !currBus.verified_user_id
		},
		openStripeIdentityVerificationSessionModal(callback) {
			const identityVerificationSuccess = 'OK';
			const identityVerificationFailed = 'KO';
			const identityVerificationProcessing = 'Processing';
			let notificationStore = useNotificationStore();

			API.init().getStripeIdentityVerificationSession(this.currBusinessId, resp => {
				if(resp && resp.result === "OK") {
					if(resp.details === 'already_verified') {
						this.refreshBusiness(this.currBusinessId, ()=>{
							notificationStore.showNotification("stripe-identity-success");
							callback && callback(identityVerificationFailed);
						});
					} else if(resp.details === 'processing') {
						this.userStore.refreshBusiness(this.currBusinessId, ()=>{
							let isProcessing = !this.currBusiness.verified_user_id;
							notificationStore.showNotification(isProcessing ? "stripe-identity-processing" : "stripe-identity-success");
							let callbackResult = isProcessing ? identityVerificationProcessing : identityVerificationSuccess;
							callback && callback(callbackResult);
						});
					} else if(resp.client_secret) {
						useScriptStore().loadScript("stripe", ()=>{
							let stripe = Stripe(STRIPE_PUBLIC_KEY_2);
							stripe.verifyIdentity(resp.client_secret)
								.then(result => {
									if (result.error) {
										notificationStore.showNotification("stripe-identity-error");
										callback && callback(identityVerificationFailed);
									} else {
										this.refreshBusiness(this.currBusinessId, ()=>{
											let isProcessing = !this.currBusiness.verified_user_id;
											notificationStore.showNotification(isProcessing ? "stripe-identity-processing" : "stripe-identity-success");
											let callbackResult = isProcessing ? identityVerificationProcessing : identityVerificationSuccess;
											callback && callback(callbackResult);
										});
									}
								})
								.catch(err => {
									console.warn('Stripe identity verification failure: ', err);
									notificationStore.showNotification("generic-fail");
									callback && callback(identityVerificationFailed);
								});
						});
					}
				} else {
					notificationStore.showNotification("generic-fail");
					callback && callback(identityVerificationFailed);
				}
			});
		}
	},
});

function checkSessionType(type, businessId) {
	let sessionKey = getSessionKeyFromType(type, businessId);
	return !!sessionStorage.getItem(sessionKey);
}

function getSessionKeyFromType(type, businessId) {
	let sessionKey = "";
	if(type === 'subscription') {
		sessionKey = "collapsed-subscription-banner-";
	} else if(type === 'link') {
		sessionKey = "collapsed-link-banner-";
	} else if(type === 'qrs') {
		sessionKey = "collapsed-qrs-banner-";
	} else if(type === 'payments') {
		sessionKey = "collapsed-payments-banner-";
	} else if(type === 'fiscal') {
		sessionKey = "collapsed-fiscal-banner-";
	} else if(type === 'order_pay') {
		sessionKey = "collapsed-order-pay-banner-";
	}
	return sessionKey += businessId;
}
