import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {getLocalDateFromServer, formatDateTime, formatDateTimeWithSeconds} from '@/shared/helpers/dates';
import {useUserStore} from "@/console-new/stores/user.store";
import {useLanguageStore} from "@/shared/stores";

export const useAdministrationStore = defineStore('administration', {
	state: () => ({
		businessTransfers: null,
		businessTransfersTotal: null,
		businessTransactions: null,
		businessTransactionsTotal: null,
		businessEpsonReceipts: null,
		businessEpsonReceiptsTotal: null,
		businessApiscontrinoReceipts: null,
		businessApiscontrinoReceiptsTotal: null,
		businessInvoices: null,
		businessBusinessToCustomerInvoices: null,
		businessInvoicesTotal: null,
		businessBalanceChanges: null,
		businessBalanceChangesTotal: null,
		businessBusinessToCustomerInvoicesTotal: null,
		activeTransfer: null,
		showStripeBottomSheet: false
	}),
	getters: {
		b2cInvoicesIds: state => {
			let finalIds = {};
			if(!state.businessBusinessToCustomerInvoices || !state.businessBusinessToCustomerInvoices.length) { return finalIds; }
			state.businessBusinessToCustomerInvoices.forEach(t => {
				finalIds[t.invoice_id] = t;
			});
			return finalIds;
		}
	},
	actions: {
		resetData() {
			this.businessTransfers = null;
			this.businessTransfersTotal = null;
			this.businessTransactions = null;
			this.businessTransactionsTotal = null;
			this.businessInvoices = null;
			this.businessBusinessToCustomerInvoices = null;
			this.businessInvoicesTotal = null;
			this.businessBalanceChanges = null;
			this.businessBalanceChangesTotal = null;
			this.businessBusinessToCustomerInvoicesTotal = null;
			this.activeTransfer = null;
		},
		getReconcileTransactionsTransfer(callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId || !this.activeTransfer) { callback && callback(false); return; }

			API.init().getReconcileTransactionsTransfer(userStore.currBusinessId, this.activeTransfer.transfer_id, r=> {
				if(r && r.result === "OK" && r.transactions) {
					if(callback) { callback(r); }
				} else {
					if(callback) { callback(false); }
				}
			});
		},
		getFilteredStripeBalance(filters, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback([]); return; }

			API.init().getFilteredStripeBalance(userStore.currBusinessId, JSON.stringify(filters || []), r=> {
				this.businessTransfers = [];
				this.businessTransfersTotal = 0;
				if(r && r.result === "OK") {
					if(r.transfers && r.transfers.selected && r.transfers.selected.length) {
						r.transfers.selected.forEach(t => {
							this.businessTransfers.push(this.normalizeTransfer(t));
						});
						this.businessTransfersTotal = Number(r.transfers.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getFilteredInvoices(filters, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback([]); return; }

			API.init().getFilteredInvoices(userStore.currBusinessId, JSON.stringify(filters || []), r=> {
				this.businessInvoices = [];
				this.businessInvoicesTotal = 0;
				if(r && r.result === "OK") {
					if(r.invoices && r.invoices.selected && r.invoices.selected.length) {
						r.invoices.selected.forEach(t => {
							this.businessInvoices.push(this.normalizeInvoice(t));
						});
						this.businessInvoicesTotal = Number(r.invoices.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getFilteredEpsonReceipts(filters, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback([]); return; }

			API.init().getFilteredEpsonReceipts(userStore.currBusinessId, JSON.stringify(filters || []), r=> {
				this.businessEpsonReceipts = [];
				this.businessEpsonReceiptsTotal = 0;
				if(r && r.result === "OK") {
					if(r.receipts && r.receipts.selected && r.receipts.selected.length) {
						r.receipts.selected.forEach(t => {
							if(r.receipts_map && r.receipts_map[t.business_receipt_epson_id]) {
								t.order_id = r.receipts_map[t.business_receipt_epson_id];
							} else {
								t.order_id = null;
							}
							this.businessEpsonReceipts.push(this.normalizeEpsonReceipt(t));
						});
						this.businessEpsonReceiptsTotal = Number(r.receipts.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getFilteredApiscontrinoReceipts(filters, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback([]); return; }

			API.init().getFilteredApiscontrinoReceipts(userStore.currBusinessId, JSON.stringify(filters || []), r=> {
				this.businessApiscontrinoReceipts = [];
				this.businessApiscontrinoReceiptsTotal = 0;
				if(r && r.result === "OK") {
					if(r.receipts && r.receipts.selected && r.receipts.selected.length) {
						r.receipts.selected.forEach(t => {
							if(r.receipts_map && r.receipts_map[t.business_receipt_id]) {
								t.order_id = r.receipts_map[t.business_receipt_id];
							} else {
								t.order_id = null;
							}
							this.businessApiscontrinoReceipts.push(this.normalizeApiscontrinoReceipt(t));
						});
						this.businessApiscontrinoReceiptsTotal = Number(r.receipts.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getFilteredBusinessToCustomerInvoices(filters, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback([]); return; }

			API.init().getFilteredBusinessToCustomerInvoices(userStore.currBusinessId, JSON.stringify(filters || []), r=> {
				this.businessBusinessToCustomerInvoices = [];
				this.businessBusinessToCustomerInvoicesTotal = 0;
				if(r && r.result === "OK") {
					if(r.invoices && r.invoices.selected && r.invoices.selected.length) {
						r.invoices.selected.forEach(t => {
							this.businessBusinessToCustomerInvoices.push(this.normalizeBusinessToCustomerInvoice(t));
						});
						this.businessBusinessToCustomerInvoicesTotal = Number(r.invoices.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getFilteredStripeTransactions(filters, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback([]); return; }

			API.init().getFilteredStripeTransactions(userStore.currBusinessId, JSON.stringify(filters || []), r=> {
				this.businessTransactions = [];
				this.businessTransactionsTotal = 0;
				if(r && r.result === "OK") {
					if(r.transactions && r.transactions.selected && r.transactions.selected.length) {
						r.transactions.selected.forEach(t => {
							this.businessTransactions.push(this.normalizeTransaction(t));
						});
						this.businessTransactionsTotal = Number(r.transactions.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getFilteredBalanceChanges(filters, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback([]); return; }

			API.init().getFilteredBalanceChanges(userStore.currBusinessId, JSON.stringify(filters || []), r=> {
				this.businessBalanceChanges = [];
				this.businessBalanceChangesTotal = 0;
				if(r && r.result === "OK") {
					if(r.changes && r.changes.selected && r.changes.selected.length) {
						r.changes.selected.forEach(b => {
							this.businessBalanceChanges.push(this.normalizeBalanceChange(b));
						});
						this.businessBalanceChangesTotal = Number(r.changes.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getCurrentShiftTransactions(todayShiftStart, todayShiftEnd, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback([]); return; }

			let formattedStartDate = formatDateTimeWithSeconds(todayShiftStart);
			let formattedEndDate = formatDateTimeWithSeconds(todayShiftEnd);
			let currentOffset = 0;
			let pageSize = 10000;
			let finalAmount = 0;
			let finalTransactions = 0;
			let finalRefunds = 0;
			let finalCancels = 0;
			let finalFees = 0;
			let fetchFn = ()=>{
				let filters = {
					"offset": currentOffset,
					"page_size": pageSize,
					"date": ">" + formattedStartDate,
					"type": ["connect_order_pos", 'pos_order'],
					"transactions.date": "<" + formattedEndDate
				};
				API.init().getFilteredStripeTransactions(userStore.currBusinessId, JSON.stringify(filters), r=> {
					if(r && r.result === "OK" && r.transactions && r.transactions.selected && r.transactions.selected_num) {
						if(r.transactions.selected.length) {
							r.transactions.selected.forEach(t => {
								if(t.status !== 'canceled') {
									finalAmount += Number(t.amount);
									finalFees += Number(t.application_fee_amount);
									if(t.refunded_amount) {
										finalAmount -= Number(t.refunded_amount);
										finalRefunds += 1;
									} else {
										finalTransactions += 1;
									}
								} else {
									finalCancels += 1;
								}
							});

						}

						if(Number(r.transactions.selected_num) > pageSize + currentOffset) {
							currentOffset += pageSize;
							fetchFn();
						} else {
							if(callback) {
								callback({
									amount: finalAmount,
									fees: finalFees,
									transactions: finalTransactions,
									refunds: finalRefunds,
									cancels: finalCancels
								});
							}

						}
					} else {
						if(callback) { callback(false, r ? r.details : null); }
					}
				});
			}
			fetchFn();


		},
		refundStripeTransaction(transactionId, amount, reason, callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback(false); }
			API.init().refundStripeTransaction(userStore.currBusinessId, transactionId, amount, reason, r => {
				if(r && r.result === "OK") {
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getStripeDashboardLink(callback = null) {
			let userStore = useUserStore();
			API.init().getStripeExpressDashboardUrl(userStore.currBusinessId, r => {
				if (r && r.result === "OK" && r.url) {
					if(callback) { callback(r.url); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		connectStripe(callback) {
			let userStore = useUserStore();
			API.init().connectStripe(userStore.currBusinessId, r => {
				if (r.result === "OK" && r.url) {
					if(callback) { callback(r.url); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		normalizeTransfer(transfer) {
			transfer.date = transfer.date ? getLocalDateFromServer(transfer.date) : null;
			transfer.amount = Number(transfer.amount || 0);
			transfer.transfer_id = Number(transfer.transfer_id || 0);
			transfer.business_id = Number(transfer.business_id || 0);
			return transfer;
		},
		normalizeInvoice(invoice) {
			invoice.date = invoice.date ? getLocalDateFromServer(invoice.date) : null;
			invoice.amount = Number(invoice.amount || 0);
			return invoice;
		},
		normalizeEpsonReceipt(receipt) {
			receipt.date = receipt.date ? getLocalDateFromServer(receipt.date) : null;
			receipt.total = Number(receipt.total || 0);
			receipt.receipt_number = Number(receipt.receipt_number || 0);
			receipt.zrep = Number(receipt.zrep || 0);
			receipt.epsonNumber = receipt.zrep.toString().padStart(4, '0')+"-"+receipt.receipt_number.toString().padStart(4, '0');
			receipt.status = "emitted";
			if(receipt.refunded === "1") {
				receipt.status = "refunded";
			} else if(receipt.refunded === "2") {
				receipt.status = "canceled";
			}
			return receipt;
		},
		normalizeApiscontrinoReceipt(receipt) {
			receipt.date = receipt.date ? getLocalDateFromServer(receipt.date) : null;
			receipt.total = Number(receipt.total || 0);
			receipt.ade_id = receipt.ade_id.split("/")[1];
			return receipt;
		},
		normalizeBusinessToCustomerInvoice(invoice) {
			let languageStore = useLanguageStore();
			invoice.date = invoice.date ? getLocalDateFromServer(invoice.date) : null;
			invoice.amount = Number(invoice.amount || 0);
			invoice.address_id = Number(invoice.address_id || 0);
			invoice.apifattura_invoice_id = Number(invoice.apifattura_invoice_id || 0);
			invoice.business_id = Number(invoice.business_id || 0);
			invoice.customer_id = Number(invoice.customer_id || 0);
			invoice.invoice_id = Number(invoice.invoice_id || 0);
			invoice.state_readable = languageStore.getString("state_"+invoice.state);
			if(invoice.address) {
				invoice.address.lat = invoice.address.lat ? Number(invoice.address.lat) : null;
				invoice.address.lng = invoice.address.lng ? Number(invoice.address.lng) : null;
			}
			invoice.error_readable = invoice.error ? languageStore.getString(invoice.error) : '-';
			return invoice;
		},
		normalizeTransaction(transaction) {
			transaction.transaction_id = Number(transaction.transaction_id || 0);
			transaction.business_id = Number(transaction.business_id || 0);
			transaction.last_charge_id = transaction.last_charge_id || null;
			transaction.order_id = transaction.order_id || null;
			transaction.date = transaction.date ? getLocalDateFromServer(transaction.date) : null;
			transaction.amount = Number(transaction.amount || 0);
			transaction.application_fee_amount = Number(transaction.application_fee_amount || 0);
			transaction.type = transaction.type || null;
			transaction.refunded_amount = Number(transaction.refunded_amount || 0);
			transaction.refund_reason = transaction.refund_reason || null;
			return transaction;
		},
		normalizeBalanceChange(b) {
			b.amount = Number(b.amount || 0);
			b.balance_pre_change = Number(b.balance_pre_change || 0);
			b.reason = b.reason || "";
			b.date = b.date ? getLocalDateFromServer(b.date) : null;
			return b;
		}
	},
});
